import React from "react";

import {
  Box,
  // Customizable Area Start
  Grid,
  styled,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { logoImage,contentCreationICon, myProfileIcon, businessIcon, settingIcon, homeIcon, latestUpdateIcon, helpCenterIcon, logoutIcon, subcribeFileIcon, rightArrowIcon, downArrowIcon, closeIcon } from './assets';
const configJSON = require("./config.js");
// Customizable Area End

import BreadcrumbNavigationPageController, {
  Props,
} from "./BreadcrumbNavigationPageController.web";

export default class BreadcrumbNavigationPage extends BreadcrumbNavigationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSideNavigation = () => {
    return (
      <MainDiv>
        <Grid container className="grid_container">
          <Grid className="menu_container" >
            <LogoImgBox>
              <img src={logoImage} alt="logoImage" className="logo_img" />
            </LogoImgBox>
            <NavBox>
            {this.renderNavBarTop()}
            {this.renderNavBarBottom()}  
            </NavBox>
          </Grid>
        </Grid>
      </MainDiv>
    )
  }

  renderMobileNavigation = () => {
    return (<>
      <Drawer open={this.state.menuToggle} onClose={this.handleToggleDrawer}>
        <MainContainer>
          <Grid container className="grid_container">
            <Grid className="menu_container" >
             <LogoBox>
              <LogoImgBox>
                <img src={logoImage} alt="logoImage" className="logo_img" />
              </LogoImgBox>
              <CloseImgBox data-testId="SideBarCloseTestID" onClick={this.handleToggleDrawer}>
              <img src={closeIcon} className="close_img" />
            </CloseImgBox>
             </LogoBox>
          <NavBox>
          {this.renderNavBarTop()}
          {this.renderNavBarBottom()}
          </NavBox>
            </Grid>
          </Grid>
        </MainContainer>
      </Drawer>
    </>)
  }

  renderNavBarTop = () => {
    return (<>
      <NavTop>
        <NavItemBox onClick={()=>{this.props.navigation.navigate("CustomisableUserProfileEdit")}}>
          <img src={myProfileIcon} className="nav_logo_img" />
          <NavItem>{configJSON.profile}</NavItem>
        </NavItemBox>
        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
              <img src={businessIcon} className="nav_logo_img" />
              <NavItem>{configJSON.business}</NavItem>
            </AccordionSummary>
            <AccordionDetails>
              <AccItem>{configJSON.myworkSpace}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>
        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
              <img src={contentCreationICon} />
              <NavItem>{'Content Creation '}</NavItem>
            </AccordionSummary>
            <AccordionDetails>
              <AccItem onClick={this.goToGenerateContent} data-testId="generateContentTestID">{configJSON.generateContent}</AccItem>
              <AccItem  onClick={()=>{this.props.navigation.navigate("Campaigns")}
              }>{'My Campaigns'}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>
        <NavItemBox>
          <img src={homeIcon} className="nav_logo_img" />
          <NavItem>{configJSON.home}</NavItem>
        </NavItemBox>
        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
              <img src={settingIcon} className="nav_logo_img" />
              <NavItem>{configJSON.settings}</NavItem>
            </AccordionSummary>
            <AccordionDetails className="acc_details">
              <AccItem>{configJSON.security}</AccItem>
              <AccItem>{configJSON.notifications}</AccItem>
              <AccItem>{configJSON.socialMedia}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>
      </NavTop>
    </>)
  }

  renderNavBarBottom = () => {
    return (<>
      <NavBottom>
        <SubscriptionMainBox>
          <SubscriptionBox>
            <ContentBox>
              <Typography className="subscription_content">{configJSON.subscription}</Typography>
              <Typography className="getMore_content">{configJSON.getMore}</Typography>
              <Typography className="content">{configJSON.content}</Typography>
            </ContentBox>
            <FileIcon>
              <img src={subcribeFileIcon} className="subcribe_file_img" />
            </FileIcon>
            <RightIcon>
              <img src={rightArrowIcon} className="right_arrow_img" />
            </RightIcon>
          </SubscriptionBox>
        </SubscriptionMainBox>
        <NavItemBox>
          <img src={latestUpdateIcon} className="nav_logo_img" />
          <NavItem>{configJSON.latestUpdates}</NavItem>
        </NavItemBox>
        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
              <img src={helpCenterIcon} className="nav_logo_img" />
              <NavItem>{configJSON.helpCenter}</NavItem>
            </AccordionSummary>
            <AccordionDetails className="acc_details">
              <AccItem onClick={this.goToInteractivefaqs} data-testId="faqTestID">{configJSON.faqHeading}</AccItem>
              <AccItem onClick={this.goToOnboardingguide} data-testId="tutorialTestID">{configJSON.tutorials}</AccItem>
              <AccItem>{configJSON.contactUs}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>
        <NavItemBox>
          <img src={logoutIcon} className="nav_logo_img" />
          <NavItemLogout>{configJSON.logout}</NavItemLogout>
        </NavItemBox>
      </NavBottom>
    </>)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={{height:'100vh', display:"flex", width:"100%"}}>
        <SideNavigationBox>
        {this.renderSideNavigation()}
        </SideNavigationBox>
        <MobileNavigationBox>
        {this.renderMobileNavigation()}
        </MobileNavigationBox>
        <Box sx={{width:"100%", height:"100%",flexGrow:1, overflowY:"auto",}}>
          {this.props.children}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SideNavigationBox = styled(Box)({
  height:"100%",
  maxWidth:"fit-content",
  overflowY:"auto",
  overflowX:"hidden",
   display: "block",
   "@media (max-width: 600px)": {
   display: "none",
  },
})

const MobileNavigationBox = styled(Box)({
   display: "none !important",
   "@media (max-width: 600px)": {
    display: "block",
   },
})

const MainDiv = styled(Box)({
  width: '100%',
  "& .menu_container": {
    background: "#0E0E0E",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    weight: 600,
    padding: "50px 0px",
  },
  "& .grid_container": {
    height: "100vh"
  }
})

const LogoBox = styled(Box)({
   position: "relative"
})


const LogoImgBox = styled(Box)({
  height: "100px",
  color: "#FFFFFF",
  textAlign: "center",
  "& .logo_img": {
    height: "70px",
    color: "#FFFFFF"
  }
})

const CloseImgBox = styled(Box)({
  position: "absolute",
  top: 20,
  left: 30,
  color: "#FFFFFF",
  display: "flex",
  cursor: "pointer",
  "& .close_img": {
    height: "25px",
    color: "#FFFFFF"
  }
})

const AccordionBox = styled(Accordion)({
  "&.MuiPaper-root": {
    background: "none !important"
  },
  "& .MuiAccordionSummary-root": {
    padding: "0px",
  },
  "& .MuiAccordionSummary-content": {
    gap: "10px",
    alignItems: "center",
  },
  "& .acc_details": {
    display: "flex",
    flexDirection: "column"
  }
})

const NavBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "last-baseline"
})

const NavTop = styled(Box)({
  width: "100%",
})

const NavBottom = styled(Box)({
  width: "100%",
})

const NavItemBox = styled(Box)({
  width: "100%",
  gap: "10px",
  margin: "10px 0px",
  display: "flex",
  cursor: "pointer",
  paddingLeft: "20px",
  "& .nav_logo_img": {
    height: "20px"
  },
  "& .nav_logoSVG_img": {
    height: "12px"
  },
  "& .active_pointer":{
    backgroundColor: "#FFFFFF",
    width: "8px",
    height: "8px",
    borderRadius: "50%"
  }
})

const NavItem = styled(Box)({
  fontFamily: "Open Sans",
  weight: 600,
  fontSize: "16px",
  color: "#FFFFFF",
})

const AccItem = styled(Box)({
  fontFamily: "Open Sans",
  weight: 600,
  fontSize: "16px",
  color: "#FFFFFF",
  padding: "8px 0px",
  paddingLeft: "15px",
  borderLeft: "2px solid #4D4D4D",
  marginLeft: "-4px"
})

const NavItemLogout = styled(Box)({
  fontFamily: "Open Sans",
  weight: 600,
  fontSize: "16px",
  color: "#F06969",
})

const SubscriptionMainBox = styled(Box)({
  backgroundColor: "#2B28D64D",
  paddingLeft: "20px"
})

const SubscriptionBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#212121",
  padding: "25px 15px",
  gap: "10px"
})

const ContentBox = styled(Box)({
  "& .subscription_content": {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#FFFFFF"
  },
  "& .getMore_content": {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#989898",
    marginTop: "10px"
  },
  "& .content": {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#3164E8"
  }

})

const FileIcon = styled(Box)({
  background: "#B7B7B726",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  "& .subcribe_file_img": {
    width: "22px",
    height: "22px",
    padding: "15px"
  }
})

const RightIcon = styled(Box)({
  marginLeft: "5px",
  "& .right_arrow_img": {
    width: "9px",
    height: "15px"
  }
})

const MainContainer = styled(Box)({
  width: '100%',
  "& .menu_container": {
    background: "#0E0E0E",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    weight: 600,
    padding: "50px 0px",
  },
  "& .grid_container": {
    height: "100vh"
  }
})

// Customizable Area End
